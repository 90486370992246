<template>
  <div class="text-l">
    <div class="seleInst w-100 o-a" :style="{height:loginH + 'px'}">
      <el-radio v-model="radio" :label="item.id" border class="w-100 m-t-10" v-for="(item,i) in institList" :key="i">
        {{item.name}}
         <span class="el-icon-success color-green f-l-r p-0" v-if="item.id == radio"></span>
      </el-radio>
    </div>
  </div>
</template>


<script>
export default {
  props:{
    institList:Array,
    loginH:Number
  },
  data(){
    return {
      radio:'',
      roleType:1,
      serverUrl:""
    }
  },
  watch:{
    radio:{
      deep:true,
      immediate:true,
      handler(val) {
        if(!val) return false
        for (const key in this.institList) {
          if (this.institList[key].id == val) {
            this.roleType = this.institList[key].roleType
            this.serverUrl = this.institList[key].serverUrl
            let info = {key:'serverUrl',value:this.serverUrl}
            this.setLocalStorage(info)  // 存储选择的机构信息 以及 对应的职位
            break            
          }
        }
      }
    }
  }
}
</script>


<style scoped>
label{
  margin-left: 0;
}
.seleInst >>> .el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 0;
}
.seleInst >>> .el-radio__input {
  display: none;
}
</style>