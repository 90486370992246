import api from "@/utils/axios";

export default {
  // 创建文件夹
  create(params) {
    return api.post("databank/catalogue/create", params);
  },
  // 获取所有文件夹
  getAllFolder(params){
    return api.post("databank/catalogue/get/list",params);
  },
  // 删除一个文件夹
  deleFolder(params) {
    return api.post("databank/catalogue/drop",params);
  },
  // 重命名文件夹
  editFolder(params){
    return api.post("databank/catalogue/change",params);
  },
  // 获取所有文件列表
  getAllDatumList(params){
    return api.post("databank/datum/all/get",params);
  },
  // 根据文件夹id获取文件夹下文件
  getFolderData(params){
    return api.post("databank/datum/catalogue/get",params)
  },
  // 文件夹下新增文件
  addFolderData(params){
    return api.post("databank/datum/create",params)
  },
  // 删除一个文件
  deleFile(params){
    return api.post("databank/datum/drop",params)
  },
  // 移动文件
  moveFile(params){
    return api.post("databank/datum/change",params)
  }
};
