<template>
  <div id="particles">
    <div class="context" ref="context" :style="{marginTop:-marTop + 'px',marginLeft:-marLeft + 'px'}">
      <!-- <i class="iconfont icon-dengluyebiaoti f-w-5" style="font-size:60px;"></i> -->
      <iconpark-icon name="xiquelogo" size="200" style="width:100%" height="70px"></iconpark-icon>
      <PhoneLogin class="m-t-70" v-show="!loginType && loginMode == 'short'" ref="phoneLogin" :editLoginH="editLoginH" :loginModl="loginModl" />
      <InstitSele class="m-t-43" v-show="loginType" ref="institSele" :institList="institList" :loginH="loginH" />
      <Erweima v-if="loginMode == 'erweima' && !loginType" class="m-t-31" :loginH="loginH" :codeSuc="codeSuc"  />
      <div class="p-r">
        <el-button class="m-t-24 w-100 loginBtn"  @click="goLogin">{{loginType ? '进入' : '登录'}}</el-button>
        <span class="f-l-r f-s-14 cu editPassword" v-if="loginMode != 'erweima'" @click="changeLoginModl">{{loginModl == 'message' ? '密码登录' : '验证码登录'}}</span>
      </div>
      <div class="login-qr-switch-box">
        <div class="switch-login-mode-wrapper">
          <div class="switchLoginMode" @click="switchLoginMode">
            <el-tooltip class="item" effect="dark" :content="loginModeText()" placement="left">
              <el-image class="switch-icon" :src="loginMode != 'erweima' ? qcode : computer"></el-image>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneLogin from '@/components/login/phoneLogin'
import InstitSele from "@/components/login/institSele"
import particles from 'particles.js'
import particlesConfig from "../../utils/particles.json";
import Erweima from '@/components/login/erweima'

export default {
  components:{
    PhoneLogin,InstitSele,Erweima
  },
  data(){
    return {
      qcode:require("@/assets/img/login/bg-qrCode.png"),
      computer:require("@/assets/img/login/computer.png"),
      loginTItle:require("@/assets/img/login/loginTitle.png"),
      loginType:false,
      institList:[], // 存放机构列表
      marTop:0,
      loginH:0,
      marLeft:0,
      loginMode:"short", // 登录方式 short 短信验证码、erweima 二维码登录
      loginModl:'password', // message 短信、password 密码
      erweimaType:false
    }
  },
  created(){
    // 是否登录
    this.whetherAutomaticLogin()
  },
  mounted(){
    
    particlesJS('particles', particlesConfig, function () {
        console.log('callback - particles.js config loaded');
    });
    let marTop = this.$refs.context.offsetHeight
    this.marTop = marTop / 2
    this.marLeft = this.$refs.context.offsetWidth / 2
  },
  methods:{
    whetherAutomaticLogin(){
      // 获取本地存储是否自动登录信息
      let automatic = this.getLocalStorage('automatic')
      if(automatic){
        // 比较两个时间戳是否相差七天
        let now = new Date().getTime()
        // 获取上次登录的时间
        let automaticTime = this.getLocalStorage('loginTime')
        let time = now - automaticTime
        if(time > 0 && time < 7 * 24 * 60 * 60 * 1000){
          this.$router.push("/plan")  // 进入主界面
        } else {
          this.removeLocalStorage(['automatic','loginTime'])
        }
      }
    },
    // 切换登录方式
    changeLoginModl(){
      if(this.loginModl == "message") {
        this.loginModl = "password"
      } else {
        this.loginModl = "message"
      }
    },
    codeSuc(res){
      this.erweimaType = true
      this.loginType = true
      this.institList = res.data.organizationList
      let info = {
        key:'userInfo',
        value:res.data
      }
      this.setLocalStorage(info)
      if(this.institList.length == 1) {
        let orgInfo = [{key:'orgId',value:this.institList[0].id},{key:'roleType',value:this.institList[0].roleType}]
        this.setLocalStorage(orgInfo)  // 存储选择的机构信息 以及 对应的职位
        this.$router.push("/plan")  // 进入主界面
      }
    },
    editLoginH(num){
      this.loginH = num + 27
    },
    goLogin(){
      if(!this.loginType) {  // 登录
        let params = this.$refs.phoneLogin.params
        let loginModl = this.$refs.phoneLogin.loginModl
        if(!this.isNotNull(params.phone,'手机号不能为空') || !this.phoneCheck(params.phone)) return false
        if(loginModl == 'message') {
          if(!this.isNotNull(params.vCode,'验证码不能为空')) return false
        } else {
          if(!this.isNotNull(params.password,'密码不能为空')) return false
        }
        let formData = new FormData();
        formData.append("phone",params.phone)
        if(loginModl == 'message') {
          formData.append("vCode",params.vCode)
        } else {
          formData.append("password",params.password)
        }
        this.Api.login.loginByVCode(formData).then(res => {
          if(res.code == 0) {
            // 存储用户是否自动登录
            let automatic = this.$refs.phoneLogin.params.automaticType
            let auto = {key:'automatic', value:automatic ? true : false}  // 是否选择了自动登录 true 是 false 否
            // 获取当前登录时间戳
            let timestamp = new Date().getTime()
            let loginTime = {key:'loginTime',value:timestamp}
            this.institList = res.data.organizationList
            this.$refs.institSele.radio = res.data.organizationList[0].id
            let info = {
              key:'userInfo',
              value:res.data
            }
            this.loginType = !this.loginType
            this.setLocalStorage([info,auto,loginTime])
          }
        })
      } else {  // 选择机构后进入
        let info = [{key:'orgId',value:this.$refs.institSele.radio},{key:'roleType',value:this.$refs.institSele.roleType}]
        localStorage.setItem('serverUrl',this.$refs.institSele.serverUrl)
        this.setLocalStorage(info)  // 存储选择的机构信息 以及 对应的职位
        this.$router.push("/plan")  // 进入主界面
      }
      
    },
    // 切换到了方式
    switchLoginMode(){
      if(this.loginMode == 'short'){
        this.loginMode = 'erweima'
      } else {
        this.loginMode = 'short'
      }
    },
    // 登录提示
    loginModeText(){
      let text = '二维码登录'
      if(this.loginMode == 'erweima') text = '验证码登录'
      return text
    }
  }
}
</script>


<style scoped>
.keepRecord {
  position: absolute;
  bottom: 0;
  left: 0;
  width:100%;margin:0 auto; padding:20px 0;
}
.editPassword {
  position: absolute;
  bottom: -35px;
  width: 100%;
  left: 0;
}
.switch-icon {
  position: absolute;
  font-size: 40px;
  color: black;
  bottom: -8px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 2rem;
  height: 2rem;
}
.switch-login-mode-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 2;
  font-size: 14px;
}
.login-qr-switch-box {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 20px;
  width: 400px;
  height: 80px;
  overflow: hidden;
  text-align: right;
}
.switchLoginMode {
  width: 100px;
  height: 100px;
  -webkit-transform: translate(-50px,-50px) rotate(45deg);
  transform: translate(-50px,-50px) rotate(45deg);
  cursor: pointer;
  /* background-color: #82a7fc; */
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  overflow: hidden;
}
#particles {
  position: relative;
  width: 100%;
  background-color: #0f0f1a;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
}
.loginBtn{
  background: black;
  color: white;
  letter-spacing:10px
}
.loginBtn:hover {
  opacity:0.8 !important;
  background: black;
  color: white;
}
.loginBtn:focus {
  background: black !important;
  color: white !important;
}
.login {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.bgClass {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.context {
  margin: auto;
  position: absolute;
  top:50%; left:50%;
  background: #FFFFFF;
  border-radius: 20px;
  z-index: 2;
  padding-left: 59px;
  padding-top: 51px;
  padding-right: 67px;
  padding-bottom: 131px;
  width: 300px;
}
</style>