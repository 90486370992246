<template>
  <div>
    <el-result :icon="icon" :title="title" :subTitle="subTitle">

    </el-result>
  </div>
</template>

<script>
export default {
  props:{
    icon:{
      type:String,
      default:"info"
    },
    title:{
      type:String,
      default:"暂无数据"
    },
    subTitle:{
      type:String,
      default:""
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>