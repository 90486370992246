import api from "@/utils/axios";

export default {
  // 获取机构下所有班级
  getAllClass(params) {
    return api.post("grade/get/all", params);
  },
  // 老师获取作业
  getHomeWork(params){
    return api.post("/homework/correct/teacher/get",params)
  },
  // 老师获取相邻的作业
  getNext(params){
    return api.post("homework/correct/teacher/get/next",params)
  }
};
