// import Vue from 'vue'
import axios from "axios";
import store from '../store'

// MessageBox
import { Loading, Message, } from 'element-ui';

axios.defaults.baseURL = localStorage.getItem('serverUrl') || "https://app.xqokr.com/30001"
let loading  //定义loading变量

let userUrl = localStorage.getItem('baseUrlType')
if (!userUrl) {
  let hostname = window.location.hostname
  if (hostname === 'uat.xqokr.com') {
    userUrl = 'https://uat-manager.xqokr.com/'
    localStorage.setItem('baseUrlType', 'https://uat-manager.xqokr.com/')
  } else if (hostname === 'www.xqokr.com') {
    userUrl = 'https://manager.xqokr.com/'
    localStorage.setItem('baseUrlType', 'https://manager.xqokr.com/')
  } else {
    userUrl = 'https://uat-manager.xqokr.com/'
    localStorage.setItem('baseUrlType', 'https://uat-manager.xqokr.com/')
  }
}
function startLoading() { //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0)'
  })
}
function endLoading() { //使用Element loading-close 方法
  loading.close()
}
// axios.defaults.withCredentials=true
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers["Content-Type"] = "text/html;charset=utf-8";
axios.defaults.headers["Content-Type"] = "application/json";
//设置超时
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  config => {
    if (config.url != '/app/getQrLoginResult' && config.url != 'lessonplan/course/change' && config.url != 'lessonplan/course/create') {
      startLoading()
    }
    if (config.url == "/app/getVerify") {
      config.responseType = 'blob'
    }
    if (config.data != undefined && config.data.baseUrl != undefined && config.data.baseUrl != '') {
      config.baseURL = config.data.baseUrl
    }
    if (config.url == "app/loginByVCode" || config.url == "/app/getUserInfoList" || config.url == "app/findPassword" || config.url == "app/loginByPassword" || config.url == "/app/getQrLoginResult" || config.url == "/app/getQrCode" || config.url == "app/getVCode" || config.url == "app/bindingOrg" || config.url == "/app/getMyInfo" || config.url == "/app/upload" || config.url == "/app/uploadBase64" || config.url == "app/getOrgMembers" || config.url == "app/getOssToken" || config.url == "/app/getVerify") {
      config.baseURL = userUrl
    } else if (config.baseURL != 'https://manager.xqokr.com/' && config.method == 'post' && config.baseURL != 'https://uat-manager.xqokr.com/') {
      let data = JSON.parse(JSON.stringify(config.data))
      if (!data.baseUrl && data.type != 'h5') {
        if (data.institution_id == undefined && data.type != 'h5') data.institution_id = JSON.parse(localStorage.getItem('orgId'))
        if (data.teacher_id == undefined && data.type != 'h5') data.teacher_id = JSON.parse(localStorage.getItem("userInfo")).id
      }
      let commentBaseUrl = store.state.comment.baseUrl
      if (commentBaseUrl) {
        config.baseURL = commentBaseUrl
      }
      config.data = data
    }
    return config
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    endLoading()
    if (response.url == "/app/getVerify") {
      return Promise.resolve(response)
    }
    if (response.status == 200) {
      if (response.config.url != "/app/getVerify" && response.data.code != 0 && response.data.msg != '用户ID不能为空!') {
        Message.error(response.data.msg)
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    endLoading()
    console.log(error);
  }
);



export default {
  post(url, data, withCredentials) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: data,
        withCredentials: withCredentials != undefined ? withCredentials : false
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};

