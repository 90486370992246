import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Api from './api/index'
import '@/utils/prototype'
import VueDragResize from 'vue-drag-resize';
import Result from '@/components/common/result'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.css'
import Vconsole from 'vconsole'
import Nodata from '@/components/nodata'

if (process.env.NODE_ENV === 'development') {
  // let vConsole = new Vconsole()
  // Vue.use(vConsole)
}

Vue.directive('disable-drag', {
  inserted(el) {
    el.addEventListener('dragstart', (e) => {
      e.preventDefault();
    });
  },
});
Vue.component('vue-drag-resize', VueDragResize);
Vue.component('Result', Result);
Vue.component('Nodata', Nodata);
Vue.use(ElementUI) // 样式封装

Vue.prototype.Api = Api  // 请求封装
Vue.prototype.theme = (seleTheme) => {
  let theme = "default"
  if (seleTheme == 2 || seleTheme == 4) {
    theme = "dark"
  } else if (seleTheme == 5) {
    theme = "flowers"
  } else if (seleTheme == 6) {
    theme = "dream"
  }
  return theme
}

Vue.prototype.themeTitle = (seleTheme) => {
  let str = "color:black"
  if (seleTheme == 2) {
    str = "color:white"
  } else if (seleTheme == 3) {
    str = "color:#000CFF"
  } else if (seleTheme == 4) {
    str = "background: linear-gradient(130deg, #FF3D92 0%, #4058FF 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"
  } else if (seleTheme == 5) {
    str = "color:#500A98"
  } else if (seleTheme == 6) {
    str = "color:#495D66"
  }
  return str
}

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + '-喜鹊okr'
  }
  if (to.meta.requireAuth) {
    let institution_id = JSON.parse(localStorage.getItem('orgId'))
    let teacher_id = JSON.parse(localStorage.getItem("userInfo"))
    if (!institution_id || !teacher_id) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
