import api from "@/utils/axios";
import login from './item/login';
import ClassApi from './item/calssApi'
import Folder from './item/folder'
import homeWork from "./item/homeWork";
import board from "./item/board";
import plan from "./item/plan";
import course from "./item/course"

export default {login,ClassApi,Folder,homeWork,board,plan,course}
