import api from "@/utils/axios";

export default {
  // 老师获取教案
  getPlan(params) {
    return api.post("lessonplan/get", params);
  },
  //   修改教案
  editPlan(params) {
    return api.post("lessonplan/change", params)
  },
  // 删除一个教案
  delePlan(params) {
    return api.post("lessonplan/drop", params)
  },
  // 复制一个教案
  copyPlan(params) {
    return api.post("lessonplan/copy", params)
  },
  // 新增教案
  addPlan(params) {
    return api.post("lessonplan/create", params)
  },
  // 教案获取老师列表
  planGetTeacher(params) {
    return api.post("lessonplan/get/teacher", params)
  },
  // 修改教案权限
  editOrg(params) {
    return api.post("lessonplan/manage/teacher", params)
  },
  // 获取教案下老师权限
  getPlanTeacher(params) {
    return api.post("lessonplan/get/teacher", params)
  },
  // 老师创建科目
  createSub(params) {
    return api.post("lessonplan/subject/create", params)
  },
  // 老师获取科目
  getSun(params) {
    return api.post("lessonplan/subject/get", params)
  },
  // 创建课程
  addCurr(params) {
    if (params.course_id) {
      return api.post("lessonplan/course/change", params)
    }
    return api.post("lessonplan/course/create", params)
  },
  // 删除课程
  deleCurr(params) {
    return api.post("lessonplan/subject/drop", params)
  },
  // 移动或复制科目
  copySub(params) {
    return api.post("lessonplan/subject/copy", params)
  },
  // 编辑课程信息
  editCourseInfo(params) {
    return api.post("lessonplan/course/change", params)
  },
  // 获取课程信息
  getCourseInfo(params) {
    return api.post("lessonplan/course/get", params)
  },
  // 拷贝或移动课程到其他科目
  moveCourse(params) {
    return api.post("lessonplan/course/copy", params)
  },
  // 废除课程
  dropCourse(params) {
    return api.post("lessonplan/course/drop", params)
  },


  // -------------------------------------- 考核点相关 ------------------------------------
  // 创建分组
  createGroup(param) {
    return api.post("/lessonplan/check/point/group/create", param)
  },
  // 通过科目类别获取考核点分组
  getGroup(param) {
    return api.post("/lessonplan/check/point/group/get/by/subject/type", param)
  },
  // 废除考核点分组
  deleGroup(param) {
    return api.post("/lessonplan/check/point/group/drop", param)
  },
  //修改考核点分组信息 
  eitdGroup(param) {
    return api.post("/lessonplan/check/point/group/change", param)
  },
  // 复制分组
  copyGroup(param) {
    return api.post("/lessonplan/check/point/group/copy", param)
  },
  // 获取所有课程考核点
  getAssess(param) {
    if (param.subject_type != undefined) {
      return api.post("lessonplan/check/point/get/by/subject/type", param)
    }
    return api.post("lessonplan/check/point/get/by/institution", param)
  },
  // 创建考核点
  creatAssess(param) {
    if (param.check_point_id != undefined) {
      return api.post("lessonplan/check/point/change", param)
    }
    return api.post("lessonplan/check/point/create", param)
  },
  // 根据考核点id获取考核点信息 数组类型
  getPoint(param) {
    return api.post("lessonplan/check/point/get/by/point/idlist", param)
  },
  // 通过课程获取教学计划
  getSchedule(param) {
    return api.post("grade/class/schedule/get/by/courseid", param)
  },
  // 删除一个KR标签
  deleAssess(param) {
    return api.post("lessonplan/check/point/drop", param)
  },
  // 获取考核点总数
  getPointNum(param) {
    return api.post("lessonplan/check/point/group/get/by/teacher/ids", param)
  },

  // -------------------------------------- 班级相关 ------------------------------------
  // 获取机构下所有班级
  getGrade(param) {
    return api.post("grade/get/all", param)
  },
  // 获取教学计划
  getCourIdSchedule(param) {
    return api.post("grade/class/schedule/get", param)
  },
  // 创建教学计划
  createSchedule(param) {
    return api.post("grade/class/schedule/create", param)
  },
  // 废除教学计划
  dropSchedule(param) {
    return api.post("grade/class/schedule/drop", param)
  },
  // 修改教学计划
  editSchedule(param) {
    return api.post("grade/class/schedule/change", param)
  },
  // 获取班级及时数据 - 根据机构、班级、课程
  getTimelyInfo(param) {
    return api.post("statistics/grade/one/day/get", param)
  },
  // 获取数据趋势
  getTrendsInfo(param) {
    return api.post("statistics/grade/continue/get", param)
  },
  // 统计页学生信息
  getStuInfo(param) {
    return api.post("statistics/get/sutdent/info",param)
  },
  // 获取统计页kr详情
  getKrInfo(param) {
    return api.post("statistics/get/check/point/sutdent/info",param)
  },
  // 获取从班级创建开始的数据
  getContinueInfo(param){
    return api.post("statistics/grade/all/continue/get",param)
  }

};
