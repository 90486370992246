<template>
  <div class="home">
    <Login /> 
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/views/login/login'
export default {
  name: 'Home',
  components:{
    Login
  },
  methods:{
    test(){
      let data = {
        uid: 25901
      }
      this.Api.user.test(data).then(res => {
        console.log(res)
      })
      console.log(this.Api)
    }
  }
}
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
}
</style>
