import api from "@/utils/axios";

export default {
  // 获取统计信息
  institutionId(params) {
    if(params.grade_id != undefined) {
      return api.post("statistics/day/attendance/get/by/courseid", params);
    }
    return api.post("statistics/day/attendance/get/by/institutionId", params);
  },
  //   获取课程详情信息
  courseid(params) {
    return api.post("statistics/day/attendance/get/by/courseid", params)
  },
  classAtt(params) {
    return api.post("statistics/day/attendance/get/by/times",params)
  },
  // 通过班级获取课程信息
  gradeid(params){
    return api.post("lessonplan/course/get/by/gradeid",params)
  }
};
