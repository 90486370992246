<template>
  <div>
    <img :src="nodata" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      nodata: require("@/assets/img/nodata.png"),
    };
  },
};
</script>