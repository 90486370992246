<template>
  <div id="app" :class="thisRouter ? 'd-p-f' : ''">
    <div
      v-if="thisRouter"
      style="height: 100%; width: 100%"
      :class="thisRouter ? 'd-p-f' : ''"
    >
      <LeftTable v-show="thisRouter" style="min-width:65px" />
      <router-view
        :style="[thisRouter ? 'flex:1' : '']"
        v-if="!$route.meta.keepAlive"
      />
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive"
          :style="[thisRouter ? 'flex:1' : '']"
        ></router-view>
      </keep-alive>
    </div>
    <div v-else style="height: 100%; width: 100%">
      <router-view />
    </div>
  </div>
</template>


<script>
import LeftTable from "@/components/common/leftTable";

export default {
  components: { LeftTable },
  data() {
    return {
      equipment:"pc"
    };
  },
  computed: {
    thisRouter() {
      let router = this.$route;
      if(router.name == "UserAgreement") return false
      if(router.query.equipment) {
        this.equipment = router.query.equipment
        if(this.equipment == 'ios') return false
      }
      if (
        router.name == "Ipadmarkdown" ||
        (router.name == "kr" && this.equipment == 'ios') ||
        (router.name == "SutData" && this.equipment == 'ios') ||
        router.name == "Ipadchart" ||
        router.name == "404错误" ||
        router.name == "Home" || 
        router.name == "Visualization"
      )
        return false;
      return true;
    },
  },
};
</script>
<style>
.primary_btn {
  background: #598af0;
  border-radius: 15px;
}
img {
  -webkit-user-select: none;
}

.no_touch {
  /* -webkit-overflow-scrolling: touch; //允许独立的滚动区域和触摸回弹 */
  /* -webkit-touch-callout: none; 禁止长按图片的默认菜单 */
  -webkit-user-select: none; /* 禁止选择文字 */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark {
  background: #202020 !important;
  color: white !important;
}

.flowers {
  background: #fffae1 !important;
  color: #500a98 !important;
}
.dream {
  background: #fffae1 !important;
  color: #495d66 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* min-width: 1440px; */
  width: 100%;
  height: 100%;
}
.leftTable {
  width: 65px;
  height: 100%;
}
.p-l-0 {
  padding-left: 0;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-l-5 {
  padding-left: 5px;
}
.f-s-18 {
  font-size: 18px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.p-15 {
  padding: 15px;
}



.popper__arrow {
  display: none !important;
}
.drakTiime .el-input__inner,
.drakTiime .el-range-input {
  background-color: #eaeaea !important;
}
.timeSeleDrak .el-date-table td.disabled div {
  background: #535353;
}
.timeSeleDrak,
.timeSeleDrak .el-picker-panel__sidebar {
  background: #4c4a4b !important;
}
.timeSeleDrak .available,
.timeSeleDrak .el-date-range-picker__header,
.timeSeleDrak .el-date-table th,
.timeSeleDrak .el-picker-panel__shortcut,
.timeSeleDrak {
  color: white;
}
.timeSeleDrak .el-date-table td.start-date div,
.timeSeleDrak .el-date-table td.in-range div {
  background: #2d2b2a;
}


.seleDay {
  width: 185px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #818181;
  border-radius: 15px;
  position: relative;
  display: flex;
  z-index: 20;
}
.seleDay span:first-child {
  padding-left: 20px;
}
.seleDay span:last-child {
  padding-right: 20px;
}
</style>
