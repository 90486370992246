import api from "@/utils/axios";
import axios from "axios";

export default {
  // 获取作业信息
  getHomeWorkInfo(params) {
    return api.post("/homework/correct/get/by/homeworkid", params);
  },
  // 老师第一次评价学生作业
  correct(params){
    return api.post("/homework/correct",params)
  },
  // 老师修改学生评价
  changeCorrect(params){
    return api.post("/homework/correct/change",params)
  },
  // 删除一条评价
  deleComment(params){
    return api.post("/homework/correct/drop",params)
  }
};
