import api from "@/utils/axios";

export default {
  // 通过班级获取课程信息
  create(params) {
    return api.post("lessonplan/course/target/create", params)
  },
  //   修改课程信息
  courseChange(param) {
    return api.post("lessonplan/course/change", param)
  },
  //   根据目标id list 获取对应的信息
  getCourseIdlist(param){
    return api.post("lessonplan/course/target/get/by/idlist", param)
  },
  // 修改课程目标信息
  editCourseInfo(param){
    return api.post("lessonplan/course/target/change",param)
  },


  //  学生数据接口
  getAllStuList(param){
    return api.post("statistics/get/sutdent/course/info",param)
  },
  // 学生数据统计-今日课程
  getDayInfo(param){
    return api.post("statistics/student/one/day/get",param)
  },
  //  学生数据统计-历史课程
  getCourse(param){
    return api.post("statistics/student/course",param)
  },
  // 学生数据统计-科目概括
  getSubjectInfo(param){
    return api.post("statistics/student/subject",param)
  },
};
