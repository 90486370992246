<template>
  <div>
   
    <div class="p-r new-scan-login-box" style="width: 200px; height: 200px; min-height: 200px;">
      <vueQr :text="enCode"  />
      <div class="newLogin_scan-shadow" v-if="invalid">
        <div class="qr-scan-mask-cover" @click="getQrCode">
          <i class="el-icon-refresh f-s-36 m-b-10"></i>
          刷新二维码
        </div>
      </div>
    </div>
     <p class="qrLoginTitle">扫码登录</p>
    <p class="base-title-subtitle">请使用喜鹊移动端扫描二维码</p>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  props:{
    loginH:Number,
    codeSuc:Function
  },
  components:{
    vueQr
  },
  data() {
    return {
      qrText:"",
      timer:null,
      enCode:"",
      invalid:false
    };
  },
  created(){
    this.getQrCode()
  },
  methods:{
    // 轮询二维码返回信息
    getQrLoginResult(){
      let formData = new FormData();
      formData.append("code",this.qrText)
      this.Api.login.getQrLoginResult(formData).then(res => {
        if(res.msg == "二维码已失效，请刷新二维码重试!") {
          clearInterval(this.timer);
          this.timer = null;
          this.invalid = true
        } else if(res.data != 'waiting') {
          this.codeSuc(res)
          clearInterval(this.timer);
          this.timer = null;
          this.$message.success("登录成功")
        }
      })
    },
    // 获取链接生成二维码
    getQrCode(){
      this.invalid = false
      this.Api.login.getQrCode().then(res => {
        if(res.code == 0) {
          this.qrText = res.data
          let info = {
            token:res.data,
            type:3
          }
          this.enCode = JSON.stringify(info)
          this.timer = window.setInterval(() => {
            setTimeout(() => {
              this.getQrLoginResult()
            }, 0);
          },1000)
        }
      })
    },
  },
  destroyed() {
//离开页面是销毁
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>


<style scoped>
.new-scan-login-box .newLogin_scan-shadow:before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  background-color: #000;
  opacity: .6;
}
.qr-scan-mask-cover {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.new-scan-login-box {
  -webkit-transition: all .3s;
  transition: all .3s;
  margin: 32px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
}
.newLogin_scan-shadow {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  width: 200px;
  height: 200px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}
.qrcode {
  display: inline-block;
  width: 132px;
  height: 132px;
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
}
.qrLoginTitle {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
}
.base-title-subtitle {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 0;
}
</style>