import api from "@/utils/axios";

export default {
  // 验证码登录
  loginByVCode(params) {
    if(params.get('password') != undefined) {
      return api.post("app/loginByPassword", params);
    }
    return api.post("app/loginByVCode", params,true);
  },
  // 绑定机构
  bindingOrg(params) {
    return api.post("app/bindingOrg", params);
  },
  // 获取用户信息
  getUserInfo(params){
    return api.post("/app/getMyInfo",params)
  },
  // 获取老师的信息 
  getUserInfoList(param){
    return api.post("/app/getUserInfoList",param)
  },
  // 上传文件
  upload(params){
    return api.post("/app/upload",params)
  },
  uploadBase64(params){
    return api.post("/app/uploadBase64",params)
  },
  // 获取机构下教职工/管理员
  getOrgPeo(params){
    return api.post("app/getOrgMembers",params)
  },
  getOssToken(params){
    return api.post("app/getOssToken",params)
  },
  // 获取验证码图片
  getVerify(param){
    return api.post("/app/getVerify",param,true)
  },
  // 获取验证码
  getVCode(param){
    return api.post("app/getVCode",param,true)
  },
  // 获取二维码登录密钥
  getQrCode(param){
    return api.post("/app/getQrCode",param,true)
  },
  // 获取二维码扫描信息 轮询
  getQrLoginResult(param){
    return api.post("/app/getQrLoginResult",param,true)
  },
  // 修改密码
  findPassword(param){
    return api.post("app/findPassword",param,true)
  }
};
