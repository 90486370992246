<template>
  <div>
    <el-dialog
      title="请输入结果"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      :before-close="handleClose"
    >
      <div>
        <el-image :src="verify"></el-image>
        <el-input v-model="Captcha" placeholder="请输入图片中的结果"></el-input>
      </div>
      <span slot="footer">
        <el-button type="" size="mini" class="cancle" @click="handleClose"
          >取消</el-button
        >
        <el-button type="" class="saveCurrBtn" size="mini" @click="getCode(Captcha)"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    handleClose:Function,
    getCode:Function
  },
  data() {
    return {
      dialogVisible:true,
      verify:"",
      Captcha:""
    };
  },
  created(){
    this.getImg()
  },
  methods:{
    getImg(){
      this.Api.login.getVerify().then(res => {
        let blob = new Blob([res], {
          type: "image/png"
        });
        let url = window.URL.createObjectURL(blob);
        // 将图片转换成img标签可以识别的url
        this.verify = url
      })
    },
  }
};
</script>

<style scoped>

</style>