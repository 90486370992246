<template>
  <div class="leftTable b-1-r" v-show="routeIndex != '/comment' && routeIndex != '/ipadchart'">
    <div class="m-t-20 m-b-60">
      <el-avatar size="small" :src="header"></el-avatar>
      <p>{{userInfo.name}}</p>
    </div>
    <div class="cu leftItem" v-for="(item,i) in leftTabList" :key="i" @click="goRouer(item)" :class="routeIndex == item.route ? 'seleLeft' :''">
      <i class="m-b-5 iconfont" :class="item.icon"></i>
      <p class="f-s-12">{{item.text}}</p>
    </div>
    
    <div class="cu logout header text-c" @click="logout">
      <span class="iconfont icon-daohanglogo cu f-s-36"></span>
      <p class="f-s-12">退出</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      projectIcon:require('@/assets/img/logo.png'),
      header:"",
      leftTabList:[
        {id:0,route:'/plan',text:'教案',icon:'icon-zuoyedianping-zhishidiandianping'},
        {id:4,route:'/ipadKr',text:'KR',icon:'icon-zuoyedianping-zhishidiandianping'},
        {id:1,route:'/task',text:'作业',icon:'icon-daohang-zuoye'},
        {id:2,route:'/board',text:'数据 ',icon:'el-icon-data-line'},
        {id:3,route:'/data',text:'资料库 ',icon:'el-icon-folder'},
      ],
      userInfo:{}
    }
  },
  created(){
    this.$nextTick(() => {
      this.getUserInfo()
    })
  },
  methods:{
    goRouer(item){
      this.$router.push(item.route)
    },
    logout(){
      this.$confirm('确定要退出吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass:'confimCancel',
          confirmButtonClass:'confimConfirm',
          type: 'warning'
        }).then(() => {
          // 清空本地存储信息
          this.removeLocalStorage(['userInfo','orgId','loginTime','roleType','automatic'])
          this.$router.push("/")
        }).catch(() => {})
    },
    getUserInfo(){
      if(!this.getUserId()) return false
      let formData = new FormData();
      formData.append("userId",this.getUserId())
      this.Api.login.getUserInfo(formData).then(res => {
        if(res.code == 0) {
          this.userInfo = res.data
          if(res.data.headImg == undefined) {
            this.header = require("@/assets/img/header.png")
          } else {
            this.header = res.data.headImg
          }
        }
      })
    }
  },
  computed:{
    routeIndex(){
      return this.$route.path
    }
  }
}
</script>


<style scoped>
.leftTable {
  width: 65px;
  position: relative;
}
.header {
  position: absolute;
  bottom: 25px;
  left: 16px;
  /* width: 32px; */
  /* height: 32px; */
}
p{
  margin: 0
}
.leftItem {
  color: rgba(32,29,50,1);
  padding-top: 10px;
  padding-bottom: 10px;
}
.leftItem:hover {
  color: #0A7AFF;
}
.seleLeft {
  color: #0A7AFF;
}
</style>