import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'',
      requireAuth: false, // 是否需要登录
    }
  },
  {
    path:'/ipadchart',
    name:'Ipadchart',
    meta: {
      title:'ipad图表组建'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/chart.vue')
  },
  {
    path:'/sutData',
    name:'SutData',
    meta: {
      title:'学生数据'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/stuData.vue')
  },
  {
    path:'/krDetial',
    name:'KrDetial',
    meta: {
      title:'KR详情'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/krDetial.vue')
  },
  {
    path:'/userAgreement',
    name:'UserAgreement',
    meta: {
      title:'喜鹊okr用户协议和隐私政策',
      requireAuth: false, // 是否需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/userAgreement.vue')
  },
  {
    path:'/visualization',
    name:'Visualization',
    meta: {
      title:'ipad图表组建'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/visualization.vue')
  },
  {
    path:'/ipadmarkdown',
    name:'Ipadmarkdown',
    meta: {
      title:'ipad在线编辑组建'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/markdown.vue')
  },
  {
    path:'/douyin',
    name:'Douyin',
    meta: {
      title:'douyin在线测试'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/douyin.vue')
  },
  {
    path:'/ipadKr',
    name:'kr',
    meta: {
      title:'ipadkr',
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ipad/krIndex.vue')
  },
  // 作业
  {
    path: '/task',
    name: 'Task',
    component: () => import(/* webpackChunkName: "about" */ '../views/task/task.vue'),
    meta: {
      keepAlive: true,
      title: '作业',
      requireAuth: true, // 是否需要登录
    },
  },
  // 点评
  {
    path: '/comment',
    name: 'Comment',
    meta: {
      keepAlive: false,
      title: '点评',
      requireAuth: true, // 是否需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/comment/comment.vue')
  },
  // 资料
  {
    path: '/data',
    name: 'Data',
    meta:{
      keepAlive:true,
      title:'资料',
      requireAuth: true, // 是否需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/data/data.vue')
  },
  // 数据
  {
    path: '/board',
    name: 'Board',
    meta:{
      title:'数据',
      requireAuth: true, // 是否需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/board/board.vue')
  },
  // 教案
  {
    path: '/plan',
    name: 'Plan',
    meta:{
      title:'教案',
      requireAuth: true, // 是否需要登录
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/teacher/plan.vue')
  },
  // canvas 测试
  {
    path: '/canvas',
    name: 'Canvas',
    component: () => import(/* webpackChunkName: "about" */ '../views/comment/test.vue')
  },
  // 404
  {
    path: '*',
    name: '404错误',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
