<template>
  <div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入手机号" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="手机验证码" prop="vCode">
          <div class="d-p-f">
            <el-input v-model="ruleForm.vCode" placeholder="请输入手机验证码" maxlength="4"></el-input>
            <el-button class="f-1 m-l-10 loginBtn" size="mini" slot="reference" @click="getImg" :disabled="!show">{{show ? '获取验证码' : '倒计时(' + count + ')'}}</el-button>
          </div>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="ruleForm.password" placeholder="请输入新密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirPpassword">
          <el-input v-model="ruleForm.confirPpassword" placeholder="再次输入新密码" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" class="cancle" size="mini">取 消</el-button>
        <el-button class="saveCurrBtn" @click="editPassword" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <CaptchaDialog v-if="CaptchaDialogVisible" :handleClose="closeCaptchaDialog" :getCode="getShort" />
  </div>
</template>

<script>
import CaptchaDialog from '@/components/login/captchaDialog'

export default {
  components:{
    CaptchaDialog
  },
  props:{
    handleClose:Function
  },
  data() {
    return {
      CaptchaDialogVisible:false,
      dialogVisible:true,
      ruleForm:{
        phone:"",
        vCode:"",
        password:"",
        confirPpassword:""
      },
      rules:{
        phone:[
           { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        vCode:[
           { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password:[
           { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
        confirPpassword:[
           { required: true, message: '请再次输入密码', trigger: 'blur' },
        ],
      },
      count:0,
      timer:null,
      show: true,
    };
  },
  methods:{
    closeCaptchaDialog(){
      this.CaptchaDialogVisible = false
    },
    editPassword(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid) {
          if(!this.phoneCheck(this.ruleForm.phone) ||  !this.identical(this.ruleForm.password,this.ruleForm.confirPpassword)) return false
          let formData = new FormData();
          formData.append("phone",this.ruleForm.phone)
          formData.append("vCode",this.ruleForm.vCode)
          formData.append("password",this.ruleForm.password)
          formData.append("confirPpassword",this.ruleForm.confirPpassword)
          this.Api.login.findPassword(formData).then(res => {
            if(res.code == 0) {
              this.handleClose()
              this.$message.success("修改成功")
            }
          })
        }
      })
    },
    // 获取验证码
    getShort(Captcha){
      if(!this.isNotNull(this.ruleForm.phone,'手机号不能为空') || !this.phoneCheck(this.ruleForm.phone) || !this.isNotNull(Captcha,'验证码不能为空')) return false
       const TIME_COUNT = 60;
      let formData = new FormData();
      formData.append("phone",this.ruleForm.phone)
      formData.append("Captcha",Captcha)
      this.Api.login.getVCode(formData).then(res => {
        if(res.code == 0) {
          this.closeCaptchaDialog() // 关闭获取验证码对话框
          if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
          }, 1000)
          }
        }
      })
    },
    // 获取图片验证码
    getImg(){
      if(!this.isNotNull(this.ruleForm.phone,'手机号不能为空') || !this.phoneCheck(this.ruleForm.phone)){
        return false
      } 
      this.CaptchaDialogVisible = true
    },
  }
};
</script>

<style scoped>
.loginBtn {
  border-color: RGBA(32,39,50,0.2);
}
.loginBtn:hover{
  opacity: 0.8;
  color: #ffffff !important;
  background: #262626 !important;
}
.loginBtn:focus {
  background: black !important;
  color: white !important;
  border: none;
}
</style>