
export default {
  namespaced: true,
  state: {
    completeType: false,  // 是否完成了点评状态 false 无 true 是
    taskList: [], // 存放点击了当前数组
    studentId:"", // 学生id
    taskInfode: 0, // 存放点击到了第几个
    canvasHomeWorkId: "", // 存放作业id 如果触发了作业点评 根据id查找作业列表对应的修改为已点评状态
    institution_id: "", // 机构id
    baseUrl: "",
    client: {},
    commonText: {
      text: "普通文本",
      type: "text",
      context: "",
      innerText: "",
      style:
        "white-space: inherit;word-wrap: break-word;position: absolute;left:0;top:0;width:100%;z-index:-1;font-size:16px;",
      textEditType: false,
      textColorType: false,
      alignment: "left",
      deepCopyType: false,
      callingType: true,
      textTitleShowType: true,
    },
    commonKr: {
      text: "关键结果",
      editType: false,
      check_point_id: "",
      check_point_explain: "",
      check_point_name: "",
      check_point_example: [],
      institution_id: "",
      teacher_id: "",
      type: "kr",
      innerText: "",
      style:
        "white-space: inherit;word-wrap: break-word;font-size: 1.5em;letter-spacing: -0.02em;",
      textEditType: false,
      textColorType: false,
      alignment: "left",
      context: "",
      deepCopyType: false,
      callingType: true,
      size: 30,
      krEditType: false,
    },
    todoInfo: {
      seleType: false,
      context: "",
      innerText: "",
    },
    itemTodo: {
      type: "todoList",
      todos: [
        { seleType: false, context: "", innerText: "" }
      ]
    },
    duowei:{
      text: "多维表格",
      template: "",
      imgInfo: {
        imgList: [
          {
            src: "",
            fit: "fill",
          },
        ],
      },
      containerWidth: 30,
      containerHeight: 100,
      contentList: [{ list: [{ list: [] }, { list: [] }] }],
      deepCopyType: false,
      callingType: true,
    },
    auxiliary:{
      text: "辅助文字",
      type: "auxiliary",
      context: "",
      innerText: "",
      style: "white-space: inherit;word-wrap: break-word;font-size: 18px;",
      textEditType: false,
      textColorType: false,
      alignment: "left",
      deepCopyType: false,
      callingType: true,
    },
    picInfo:{
      text: "单张图片",
      type: "img",
      src: "",
      offsetWidth: 20,
      offsetHeight: 200,
      lastOffsetWidth: 0,
      lastOffsetHeight: 0,
      editType: false,
      cropperType: false,
      scale: 200 / 143,
      lastScale: 0,
      alignment: "left",
      lastAlignment: "left",
      leftOperateType: false,
      cropperWidth: 0,
      cropperHeight: 0,
      deepCopyType: false,
      callingType: true,
    },
  },
  mutations: {
    changeStudentId(state,val){
      state.studentId = val
    },
    changeCompleteType(state, val) {
      state.completeType = val
    },
    // 修改当前点击的作业数组
    editTaskList(state, val) {
      state.taskList = val
    },
    // 修改点击到了第几个作业
    editTaskInfode(state, val) {
      state.taskInfode = val
    },
    // 修改作业id
    editCanvasHomeWorkId(state, val) {
      state.canvasHomeWorkId = val
    },
    editBaseUrl(state, val) {
      state.baseUrl = val
    },
    editClient(state, val) {
      state.client = val
    }
  }
}