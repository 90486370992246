import Vue from "vue";
import { Message } from 'element-ui'
// 数据存储到本地
Vue.prototype.setLocalStorage = (params) => {
  if (params.length == undefined) {
    localStorage.setItem(params.key, JSON.stringify(params.value));
  } else {
    params.forEach((element) => {
      localStorage.setItem(element.key, JSON.stringify(element.value));
    });
  }
};
// 删除本地存储的数据
Vue.prototype.removeLocalStorage = (params) => {
  if (params.length == undefined) {
    return localStorage.removeItem(params);
  } else {
    params.forEach((element) => {
      localStorage.removeItem(element);
    });
  }
}
// 获取本地存储的数据
Vue.prototype.getLocalStorage = (params) => {
  return JSON.parse(localStorage.getItem(params));
};
// 获取用户的id
Vue.prototype.getUserId = () => {
  if(localStorage.getItem("userInfo")) {
    return JSON.parse(localStorage.getItem("userInfo")).id;
  }
  return null
};
// 获取用户机构id
Vue.prototype.getInsId = () => {
  return JSON.parse(localStorage.getItem('orgId'))
}
Vue.prototype.identical = (one, two, text = '两次输入的密码不一致') => {
  if (one != two) {
    Message.error(text)
    return false
  }
  return true
}
// 判空校验
Vue.prototype.isNotNull = (params, text = '不能为空') => {
  if (params == '' || params == undefined || params == null) {
    Message.error(text)
    return false
  }
  return true
}
// 判断是否为网址
Vue.prototype.isUrl = str => {
  if (!/^(https:\/\/|http:\/\/|ftp:\/\/|rtsp:\/\/|mms:\/\/)?[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/.test(str)) {
    Message.error('请输入正确的网址')
    return false
  }
  return true;
}
// 手机号校验
Vue.prototype.phoneCheck = (phone, text = '请输入正确的手机号') => {
  if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(phone)) {
    Message.error(text)
    return false
  }
  return true
}
// 获取图片base64信息

// 时间戳转换 日期
Vue.prototype.unixTimeToDate = (unixtime) => {
  var d = new Date(parseInt(unixtime) * 1000); // 依情况进行更改 * 1
  return (d.getFullYear()) + '-' + (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate());
}
// 时间戳转换
Vue.prototype.formatDateClass = (item_date) => {
  let date = new Date(parseInt(item_date) * 1000);
  let YY = date.getFullYear() + "-";
  let MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return YY + MM + DD;
}
// 带时分秒
Vue.prototype.formatDate = (item_date) => {
  let date = new Date(parseInt(item_date) * 1000);
  let YY = date.getFullYear() + "-";
  let MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + " " + hh + mm + ss;
}
Vue.prototype.formatDateImg = (item_date) => {
  let date = new Date(parseInt(item_date) * 1000);
  let YY = date.getFullYear();
  let MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1);
  let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
  let mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
  let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD + hh + mm;
}
// 中国标准时间转时间格式
Vue.prototype.timeForm = date => {
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  var d = date.getDate()
  d = d < 10 ? '0' + d : d
  var h = date.getHours()
  h = h < 10 ? '0' + h : h
  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  // + ' ' + h + ':' + minute + ':' + second
  return y + '-' + m + '-' + d
}
// 合格不合格
Vue.prototype.achText = (text) => {
  if (text == 'A') {
    return '优秀'
  } else if (text == 'B') {
    return '合格'
  } else if (text == 'C') {
    return '不合格'
  }
  return '无'
}
// 颜色选择
Vue.prototype.colorSele= (type) => {
  if(type=='A') {
    return '#F6AF45'
  } else if(type == 'B') {
    return '#68D28C'
  } else if(type == 'C') {
    return '#EC7766'
  } else if(type == '已完成') {
    return '#4381FF'
  } else if(type == '未完成') {
    return '#50677C'
  }
}
// 优秀
Vue.prototype.finType = text => {
  if (text == 1) {
    return '完成'
  } else if (text == 2) {
    return '未完成'
  } else {
    return '老师修改'
  }
}
// 科目列表
Vue.prototype.subList = () => {
  return [
    { id: 1, text: "素描" },
    { id: 2, text: "色彩" },
    { id: 3, text: "设计" },
    { id: 4, text: "速写" },
    { id: 7, text: "创作" },
    { id: 8, text: "其他" },
  ]
}
// 科目转换中文
Vue.prototype.sunName = num => {
  if (num == 1) return '素描'
  if (num == 2) return '色彩'
  if (num == 3) return '设计'
  if (num == 4) return '速写'
  if (num == 7) return '创作'
  if (num == 8) return '其他'
}
Vue.prototype.sunColor = num => {
  if (num == 1) return "#7C4DFF"
  if (num == 2) return "#F50057"
  if (num == 3) return "#F9A825"
  if (num == 4) return "#35C759"
  if (num == 7) return "#455B64"
  if (num == 8) return "#33a3dc"
}
Vue.prototype.subText = text => {
  if (text == '素描') return "#7C4DFF"
  if (text == '色彩') return "#F50057"
  if (text == '设计') return "#F9A825"
  if (text == '速写') return "#35C759"
  if (text == '创作') return "#455B64"
  if (text == '自定义') return "#636A74"
  return "#33a3dc"
}
// 课程选择颜色数组
Vue.prototype.classColorList = () => {
  return ["#FFCC00", "#FF9500", "#35C759", "#5BC8FA", "#0A7AFF", "#5956D6", "#FF375F", "#F50057", "#E040FB", "#7C4DFF", "#448AFF", "#1DE9B6", "#F9A825", "#36CFC9"]
}
// 权限列表
Vue.prototype.planJurList = () => {
  return [
    { id: 1, text: '仅自己可见可编辑' },
    { id: 2, text: '所有人可见自己可编辑' },
    { id: 3, text: '所有人可见可编辑' },
    { id: 4, text: '自定义' },
  ]
}
Vue.prototype.teacherJurList = () => {
  return [
    { id: 1, text: "可读" },
    // {id:2,text:"可写"},
    { id: 3, text: "可编辑" },
  ]
}
Vue.prototype.commentList = () => {
  return [
    { id: 1, text: '知识点' },
    { id: 2, text: '分数' },
    { id: 3, text: '问题点评' },
  ]
},
  Vue.prototype.newCommenList = () => {
    return [
      { id: 1, text: '评级点评' },
      { id: 2, text: '分数点评' },
    ]
  }
Vue.prototype.day = num => {
  let text = '上午'
  if (num == 2) text = '下午'
  else if (num == 3) text = '晚上'
  return text
}

Vue.prototype.attStatus = (num, i) => {
  let str = ""
  if (i == 0) {
    if (num == "1") {
      str = "签到"
    } else if (num == "2") {
      str = "旷课"
    } else if (num == "3") {
      str = "请假"
    } else if (num == "4") {
      str = "迟到"
    } else if (num == "5") {
      str = "早退"
    }
  } else if (i == 1) {
    if (num == "1") {
      str = "未交"
    } else if (num == "2") {
      str = "旷课"
    } else if (num == "3") {
      str = "请假"
    } else if (num == "4") {
      str = "迟到"
    } else if (num == "5") {
      str = "早退"
    }
  } else if (i == 2) {
    if (num == "1") {
      str = "未完成"
    } else if (num == "2") {
      str = "旷课"
    } else if (num == "3") {
      str = "请假"
    } else if (num == "4") {
      str = "迟到"
    } else if (num == "5") {
      str = "早退"
    } else if(num == "6") {
      str = "老师修改"
    }
  } else if (i == 3) {
    if (num == "1") {
      str = "未点评"
    } else if (num == "2") {
      str = "未完成"
    } else if (num == "3") {
      str = "老师修改"
    }
  } else if (i == 4) {
    if (num == 1) {
      str = "不合格"
    }
  } else if (i == 5) {
    if (num == 1) {
      str = "合格"
    }
  } else if (i == 6) {
    if (num == 1) {
      str = "优秀"
    }
  }

  return str
}
Vue.prototype.dayList = () => {
  return [{ id: 1, text: '上午', type: false }, { id: 2, text: '下午', type: false }, { id: 3, text: '晚上', type: false },]
}
Vue.prototype.dataURLtoBlob = (dataurl, name) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name, {
    type: mime
  });
}
// 阿里oss缩略图
Vue.prototype.ossQ = () => {
  return '?x-oss-process=image/quality,q_50'
}
Vue.prototype.ratio = (src) => {
  if (!src) return ''
  const keyword = 'x-oss-process=image/rotate'
  if (src.includes(keyword)) {
    return src + ',quality,q_80'
  } else {
    return src + '?x-oss-process=image/quality,q_80'
  }

}
// ，分割的字符串返回有多少条
Vue.prototype.stringLength = str => {
  if (str) {
    let arr = str.split(',')
    return arr.length
  }
  return false
}
// 调用ios提示框
Vue.prototype.iosPrompt = object => {
  if (!object.isSuccess) object.isSuccess = 2
  if (object.device == "h5" || object.device == "pc") {
    if (object.isSuccess == 2) {
      Message.error(object.text)
    } else {
      Message.success(object.text)
    }
  } else {
    window.webkit.messageHandlers.hubTipText.postMessage(object);
  }
}

// 根据主题切换背景色
Vue.prototype.themeColor = info => {
  let color = ``
  if (info && info.theme == '2') {
    if (info.type == 'bigBox') {
      color = `#292929`
    } else if (info.type == 'itemBox') {
      color = `#3a3a3a`
    }
  } else {
    if (info.type == 'bigBox') {
      color = `#e3e5e7`
    } else if (info.type == 'itemBox') {
      color = `#eff1f2`
    }
  }
  return color
}

