<template>
  <div ref="phoneRef">
    <p class="f-s-16 text-l">手机号登录</p>
    <!-- 手机号输入框 -->
    <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone" v-model="params.phone" maxlength="11" />
    <!-- 验证码输入框 和 获取验证码按钮 -->
    <div class="m-t-24 text-l d-p-f" v-if="loginModl == 'message'">
      <el-input v-model="params.vCode" placeholder="请输入验证码" prefix-icon="el-icon-message" maxlength="4" class="passwordInput"></el-input>
      <el-button class="f-l-r f-1 m-l-5 loginBtn" @click="getImg" :disabled="!show">{{show ? '获取验证码' : '倒计时(' + count + ')'}}</el-button>
    </div>
    <el-input v-model="params.password" placeholder="请输入密码" prefix-icon="el-icon-lock" type="password" class="m-t-24" v-else></el-input>
    <div class="text-l m-t-24">
      <el-checkbox v-model="params.automaticType" >自动登录</el-checkbox>
      <span class="f-l-r f-s-14 cu color-blue" v-if="loginModl != 'message'" @click="editPassDialogType = true">忘记密码?</span>
    </div>
   
   
    <!-- 修改密码对话框 -->
    <EditPassword v-if="editPassDialogType" :handleClose="closeEditPassDialog" />
    <!-- 获取验证码对话框 -->
    <CaptchaDialog v-if="CaptchaDialogVisible" :handleClose="handleClose" :getCode="getCode" />
   
  </div>
</template>

<script>
import EditPassword from '@/components/login/editPassword'
import CaptchaDialog from '@/components/login/captchaDialog'
export default {
  components:{
    EditPassword,CaptchaDialog
  },
  props:{
    editLoginH:Function,
    loginModl:String
  },
  data() {
    return {
      CaptchaDialogVisible:false,
      params :{
        phone:'',
        vCode:'',
        automaticType:false,
        password:""
      },
      editPassDialogType:false,
      show: true,
      count: '',
      timer: null,
    }
  },
  created(){
    this.$nextTick(() => {
      this.editLoginH(this.$refs.phoneRef.offsetHeight)
    })
  },
  methods :{
    closeEditPassDialog(){
      this.editPassDialogType = false
    },
    handleClose(){
      this.CaptchaDialogVisible = false
    },
    getImg(){
      if(!this.isNotNull(this.params.phone,'手机号不能为空') || !this.phoneCheck(this.params.phone)) return false
      this.CaptchaDialogVisible = true
    },
    
    getCode(Captcha){
     if(!this.isNotNull(this.params.phone,'手机号不能为空') || !this.phoneCheck(this.params.phone) || !this.isNotNull(Captcha,'验证码不能为空')) return false
     const TIME_COUNT = 60;
      let formData = new FormData();
      formData.append("phone",this.params.phone)
      formData.append("Captcha",Captcha)
      this.Api.login.getVCode(formData).then(res => {
        if(res.code == 0) {
          this.handleClose() // 关闭验证码对话框
          if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
          }, 1000)
          }
        }
      })
     
   }  
  }
};
</script>

<style scoped>

.passwordInput {
  width :80%
}
.loginBtn {
  border-color: RGBA(32,39,50,0.2);
}
.loginBtn:hover{
  opacity: 0.8;
  color: #ffffff !important;
  background: #262626 !important;
}
.loginBtn:focus {
  background: black !important;
  color: white !important;
  border: none;
}

</style>
